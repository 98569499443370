<template>
  <div
    :class="{ 'cursor-wait': isLoading, 'pointer-events-none': isLoading }"
    class="fixed top-0 bottom-0 left-0 right-0 bg-black opacity-60 z-[600]"
  ></div>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 z-[610] pt-xl md:p-[4%]"
    :class="{ 'cursor-wait': isLoading, 'pointer-events-none': isLoading }"
  >
    <ContainerBasic :container-type="EContainerType.CONTENT" classes="h-full">
      <div
        class="relative flex items-end justify-center h-full md:items-center"
      >
        <div
          ref="el"
          class="relative w-full md:max-w-[510px] min-w-[345px] max-h-full h-fit overflow-auto md:overflow-visible rounded-t-alt-md md:rounded-alt-lg md:flex md:flex-col md:justify-center"
        >
          <template v-if="dialogStore.headline">
            <div
              class="flex sticky top-0 z-510 flex-row items-center justify-between w-full bg-primary-lightest px-md py-sm h-[56px] rounded-t-alt-md"
            >
              <div class="font-bold font-deco-alt text-alt-2xl">
                {{ t(dialogStore.headline) }}
              </div>
              <slot name="headline-suffix" />
              <button
                type="button"
                class="text-xl cursor-pointer text-border-dark"
                @click="dialogStore.closeDialog(DialogResponses.CANCEL)"
              >
                <FaIcon icon-class="fas fa-xmark" />
              </button>
            </div>
          </template>
          <div
            class="overflow-visible bg-white md:overflow-auto md:rounded-b-alt-md pb-sm"
            :class="{
              'h-[100%] md:h-auto md:max-h-[100%]': !dialogStore.headline,
              'h-[calc(100%-56px)] md:h-auto md:max-h-[calc(100%-56px)]':
                dialogStore.headline,
            }"
          >
            <slot />
            <div id="dialogBody"></div>
          </div>
          <button
            v-if="!dialogStore.headline && showCloseButton"
            type="button"
            class="absolute text-xl cursor-pointer text-border-dark top-md right-md"
            @click="dialogStore.closeDialog(DialogResponses.CANCEL)"
          >
            <FaIcon icon-class="fas fa-xmark" />
          </button>
        </div>
      </div>
    </ContainerBasic>
  </div>
</template>
<script setup lang="ts">
import ContainerBasic from '@/components/components/container-basic.vue';
import { useDialogStore, DialogResponses } from '@/stores/useDialogStore';
import { EContainerType } from '@/@types/container-type';
import FaIcon from '@/components/fa-icon.vue';
import { onClickOutside } from '@vueuse/core';

const props = defineProps({
  showCloseButton: {
    type: Boolean,
    default: true,
  },
  closeOnOutsideClick: {
    type: Boolean,
    default: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const { t } = useTrans();
const el = ref<HTMLElement | null>(null);

const dialogStore = useDialogStore();

onMounted(() => {
  usePreventBodyScroll(true);
});

onUnmounted(() => {
  usePreventBodyScroll(false);
});

watch(useRouter().currentRoute, () => {
  if (dialogStore.open) dialogStore.closeDialog();
});

onClickOutside(el, () => {
  if (props.closeOnOutsideClick) dialogStore.closeDialog();
});
</script>
